/** @format */

import Modal from '@atoms/Modal';
import { NOTIFICATION, useNotifications } from '@atoms/Notification';
import { CurrencyProvider } from '@common/application/context/CurrencyProvider';
import useInterval from '@common/application/hooks/useInterval/useInveral';
import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import NotificationBanner from '@molecules/NotificationBanner';
import LoadModal from '@organisms/LoadModal';
import Main from '@quarks/Main';
import Page from '@quarks/Page';
import { Header } from '@templates/App/Header';
import { signOut, signIn } from 'next-auth/react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export function App({ Component, pageProps }: AppProps) {
  const session = useSecureSession<BaseRealmRole, BaseRole>();
  const notification = useNotifications();
  const [shiftPressed, setShiftPressed] = useState(false);
  const [notificationsSent, setNotificationsSent] = useState([]);
  const [showLoadModal, setShowLoadModal] = useState(false);

  useEffect(() => {
    console.log(session);
  }, [session]);

  useInterval(() => {
    const now = new Date();
    if (!session?.data?.session_expires_at && !session?.data?.expires) {
      return;
    }
    if (session?.data?.error || !session?.data) {
      setTimeout(() => signIn(), 10000);
      if (!notificationsSent.includes('error') && !notificationsSent.includes('last')) {
        setNotificationsSent((n) => [...n, 'error']);
        notification.notify({
          variant: NOTIFICATION.ERROR,
          message: <FormattedMessage defaultMessage="Your session has expired! Signing you out..." description="Session expired notification message" />,
          indefinite: true,
        });
      }
    }

    const expires = session.data.session_expires_at ? new Date(session.data.session_expires_at * 1000) : new Date(session.data?.expires);
    const timeLeft = Math.max(0, expires.valueOf() - now.valueOf());
    if (!timeLeft && session.data.session_expires_at && !notificationsSent.includes('last')) {
      notification.notify({
        variant: NOTIFICATION.ALERT,
        message: <FormattedMessage defaultMessage="Your session has expired! Signing you out..." description="Session expired notification message" />,
        indefinite: true,
      });
      setTimeout(() => signOut(), 3000);
      setNotificationsSent((n) => [...n, 'last']);
    } else if (session.data.session_expires_at && timeLeft < 1000 * 60 && !notificationsSent.includes('min')) {
      notification.notify({
        variant: NOTIFICATION.ALERT,
        message: (
          <FormattedMessage
            defaultMessage="Your session will expire in less than 1 minute. Logout and back in now or you will be logged out in 1 minute."
            description="Session will expire in 1 minute notification message"
          />
        ),
        indefinite: true,
      });
      setNotificationsSent((n) => [...n, 'min']);
    } else if (session.data.session_expires_at && timeLeft < 1000 * 60 * 5 && !notificationsSent.includes('5min') && !notificationsSent.includes('min')) {
      notification.notify({
        variant: NOTIFICATION.ALERT,
        message: (
          <FormattedMessage
            defaultMessage="Your session will expire in less than 5 minutes. Logout and back in now or you will be logged out in 5 minutes."
            description="Session will expire in 5 minutes notification message"
          />
        ),
        indefinite: true,
      });
      setNotificationsSent((n) => [...n, '5min']);
    }
  }, 1000);

  const checkLoadShortcut = useCallback(
    (e: KeyboardEvent) => {
      // if(e.shiftKey)
      if (e.key === 'Shift') {
        if (shiftPressed) {
          setShowLoadModal((bool) => !bool);
          setShiftPressed(false);
        } else {
          setShiftPressed(true);
          setTimeout(() => {
            setShiftPressed(false);
          }, 300);
        }
      } else {
        setShiftPressed(false);
      }
    },
    [shiftPressed],
  );

  useEffect(() => {
    document.addEventListener('keyup', checkLoadShortcut, false);
    return () => {
      document.removeEventListener('keyup', checkLoadShortcut, false);
    };
  }, [checkLoadShortcut]);

  const Layout = (Component as unknown as { Layout }).Layout ? (Component as unknown as { Layout }).Layout : React.Fragment; // TODO: This is brittle AF, extent the type properly to support NEXT.js Layouts w/ Typescript

  if (session?.status !== 'authenticated') {
    return <>{session?.status}</>;
  }
  return (
    <CurrencyProvider>
      <Head>
        <meta charSet="utf-8" />
        <title>TrackUp</title>
      </Head>
      <Page>
        <NotificationBanner />
        <Header />
        <Main>
          <Layout>
            <Modal
              state={{
                isOpen: showLoadModal,
                close: () => {
                  setShowLoadModal(false);
                },
                open: () => setShowLoadModal(true),
                setOpen: (bool) => setShowLoadModal(bool),
                toggle: () => setShowLoadModal((b) => !b),
              }}
              isDismissable
            >
              <LoadModal closeModal={() => setShowLoadModal(false)} />
            </Modal>
            <Component {...pageProps} />
          </Layout>
        </Main>
      </Page>
    </CurrencyProvider>
  );
}

export default App;
