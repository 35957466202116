/** @format */

import { DocumentTemplate } from '@common/application/enums/DocumentTemplate';
import { Resource } from '@common/application/enums/Resource';
import { ResourceUrl } from '@common/application/helpers/generateSignedUrlsForResults';
import uploadFileToSignedUrl from '@common/application/helpers/uploadFileToSignedUrl';
import { IDocumentImage, TDocumentStyle } from '@common/application/hooks/useDocument';
import { SavingRaw } from '@common/application/model/Saving';
import { Account } from '@common/model/apiClient/Account';
import { Branch } from '@common/model/apiClient/Branch';
import { CompanyBrand } from '@common/model/apiClient/CompanyBrand';
import { Contact } from '@common/model/apiClient/Contact';
import { DocumentSchedule } from '@common/model/apiClient/DocumentSchedule';
import { DocumentClient } from '@common/model/apiClient/index';
import { Site } from '@common/model/apiClient/Site';
import { Tag } from '@common/model/apiClient/Tag';
import { CompanyDocumentStyle } from '@prisma/client';
import axios from 'axios';
import { isPlainObject } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export interface DocumentTag {
  documentId: number;
  companyId: number;
  tag: Tag;
  tagId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}
export class Document {
  id?: number;
  description: string;
  title: string;
  results: string;
  notes: string;
  branch: Branch;
  site: Site;
  contact: Contact;
  author: {
    id: string;
    givenName: string; //keycloak.user?.['given_name'],
    familyName: string; //keycloak.user?.['family_name'],
    email: string; //keycloak.user?.['email'],
  };
  createdBy: {
    id: string;
    givenName: string; //keycloak.user?.['given_name'],
    familyName: string; //keycloak.user?.['family_name'],
    email: string; //keycloak.user?.['email'],
  };
  chart: boolean;
  percent: boolean;
  calculations: boolean;
  cover: boolean;
  logo: boolean;
  brands: Array<{ brand: CompanyBrand }>;
  savings: Array<SavingRaw>;
  documentSchedules: Array<DocumentSchedule>;
  companyId: number;
  company: { logo: ResourceUrl; companyDocumentStyle: CompanyDocumentStyle };
  account: Account;
  images: Array<IDocumentImage>;
  templateVersion: DocumentTemplate;
  showImages: boolean;
  styles: Record<DocumentTemplate, TDocumentStyle>;
  bannerId?: number;
  accountId: number;
  savingsTotal?: string;
  tags?: Array<Tag>;
  deletedAt: Date | null;

  static list(
    query,
    sort,
    queryOpts: Record<string, unknown>,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: DocumentClient }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/documents`, {
          params: {
            sequence_id: 1,
            pit,
            query,
            queryOpts: JSON.stringify(queryOpts),
            sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static autocomplete(query, config) {
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/documents/search`, {
          signal: config.signal,
          params: {
            sequence_id: 1,
            query,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static get(id: number): Promise<{ message: string; paginated: boolean; payload: Document }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/documents/${id}`).then((res) => res.data);
  }

  static processImagesForSignedUrlUpload(payload) {
    const fileData = [];

    function processObject(obj) {
      if (isPlainObject(obj)) {
        const newObj = {};

        for (const key in obj) {
          if (Array.isArray(obj[key])) {
            newObj[key] = obj[key].map((item) => {
              if (isPlainObject(item)) {
                return processObject(item);
              } else {
                return item;
              }
            });
          } else if (key === 'images' && isPlainObject(obj[key]) && obj[key].hasOwnProperty('create') && obj[key].create) {
            newObj[key] = {
              ...obj[key],
              create: obj[key]?.create?.map((image) => {
                const id = uuidv4();
                fileData.push({ id: id, file: image.url });
                const updatedImage = {
                  ...image,
                  id: id,
                  fileExt: 'jpeg', // Change this to your desired value
                };
                delete updatedImage.url;
                return updatedImage;
              }),
            };
          } else if (isPlainObject(obj[key])) {
            newObj[key] = processObject(obj[key]);
          } else {
            newObj[key] = obj[key];
          }
        }

        return newObj;
      } else {
        return obj;
      }
    }

    const modifiedPayload = processObject(payload);
    return { payload: modifiedPayload, fileData };
  }
  static save(documentId, payload) {
    const { payload: mappedPayload, fileData } = DocumentClient.processImagesForSignedUrlUpload(payload);

    return axios.patch(`/api/v1/documents/${documentId}`, { payload: mappedPayload }).then(async (res) => {
      const { signedUrls } = res.data.payload;
      if (signedUrls) {
        for await (const url of signedUrls) {
          const file = fileData.find((f) => f.id === url.id);
          await uploadFileToSignedUrl(url.url, file.file, {}, 'image', Resource.DOCUMENT_IMAGE);
        }
      }
      return res.data;
    });
  }
  static async saveNew(payload) {
    const { payload: mappedPayload, fileData } = DocumentClient.processImagesForSignedUrlUpload(payload);

    return axios.post(`/api/v1/documents`, { payload: mappedPayload }).then(async (res) => {
      const { signedUrls } = res.data.payload;
      if (signedUrls) {
        for await (const url of signedUrls) {
          const file = fileData.find((f) => f.id === url.id);
          await uploadFileToSignedUrl(url.url, file.file, {}, 'image', Resource.DOCUMENT_IMAGE);
        }
      }
      return res.data;
    });
  }

  static delete(id: number): Promise<Record<string, unknown>> {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/documents/${id}`).then((res) => ({ ...res.data }));
  }

  static undelete(id: number): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/documents/${id}/undelete`).then((res) => ({ ...res.data }));
  }
}
