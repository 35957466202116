/** @format */

import CentredLoadingSpinner from '@atoms/CentredLoadingSpinner';
import { cssVars } from '@atoms/GlobalStyles';
import LoadingSpinner from '@atoms/LoadingSpinner';
import Scrollbar from '@atoms/Scrollbar';
import TooltipButton from '@atoms/TooltipButton';
import { Account } from '@common/model/apiClient/Account';
import styled from '@emotion/styled';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import Text from '@atoms/Text';
import { css } from '@emotion/react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { FormattedMessage } from 'react-intl';
interface ListProps {
  categories: Array<IDashboardListCategory>;
  setShowTags(bool: boolean): void;
}

export interface IDashboardListCategory {
  icon: IconName;
  id: string;
  records: Array<Record<string, any>>;
  label: string | EmotionJSX.Element;
  render(d: Record<string, any>): ReactNode;
  loading?: boolean;
}

const StyledDashboardList = styled.div`
  background: ${cssVars.white};
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  height: 100%;
  width: 100%;
  flex: 1;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  flex: 1;
  > div {
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    padding: 16px;
    gap: 16px;
  }
`;

const ListHeader = styled.div`
  border-radius: 3px 3px 0 0;
  color: ${cssVars.white};
  background: ${cssVars.charcoal};
  padding-inline: 8px;
  height: 45px;
  min-height: 45px;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: flex-end;
  container-type: inline-size;
`;

const HeaderText = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;

  @container (max-width: 300px) {
    font-size: 0.7em;
  }
`;

const TabGroup = styled.div`
  display: flex;
  height: 90%;
  gap: 2px;
`;

const Tab = styled.div<{ selected?: boolean }>`
  border-radius: 3px 3px 0 0;
  background: ${(props) => (props.selected ? cssVars.white : cssVars.blue)};
  width: 30px;
  padding: 4px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  transform-origin: bottom;

  :hover {
    background: ${(props) => (props.selected ? cssVars.white : '#70bfff')};
    > svg {
      background: ${(props) => (props.selected ? cssVars.blue : '#70bfff')};
    }
    transform: translateY(${(props) => (props.selected ? 0 : '-2px')});
  }
  > svg {
    background: ${cssVars.blue};
    font-size: 14px;
    color: ${cssVars.white};
    height: 15px;
    width: 15px;
    border-radius: 50vh;
    padding: 6px;
  }
`;

function DashboardList(props: ListProps) {
  const [selectedGroupId, setSelectedGroupId] = useState(props.categories[0].id);
  const selectedGroup = useMemo(() => props.categories.find((g) => g.id === selectedGroupId), [props.categories, selectedGroupId]);

  useEffect(() => {
    if (props.setShowTags) {
      props.setShowTags(selectedGroupId === 'tag');
    }
  }, [selectedGroupId, props.setShowTags]);

  return (
    <StyledDashboardList>
      <ListHeader>
        <HeaderText>{selectedGroup.label}</HeaderText>
        <TabGroup>
          {props.categories.map((g) => (
            <TooltipButton
              key={`${g.id}tab`}
              tooltip={g.label}
              onClick={() => {
                setSelectedGroupId(g.id);
              }}
            >
              <Tab selected={selectedGroupId === g.id}>
                <FontAwesomeIcon icon={['fas', g.icon]} />
              </Tab>
            </TooltipButton>
          ))}
        </TabGroup>
      </ListHeader>
      <div
        css={css`
          //flex: 1;
          //width: 100%;
          height: 100%;
          overflow: hidden;
        `}
      >
        <Scrollbar>
          <ListContainer>
            {selectedGroup.loading ? (
              <CentredLoadingSpinner />
            ) : // <div
            //   css={css`
            //     position: absolute;
            //     inset: 0;
            //     display: grid;
            //     place-items: center;
            //     svg {
            //       height: 50px;
            //     }
            //   `}
            // >
            //   <LoadingSpinner />
            // </div>
            !!selectedGroup.records?.length ? (
              selectedGroup.records.map((r) => <div key={r.id}>{selectedGroup.render(r)}</div>)
            ) : (
              <div
                css={css`
                  height: 100%;
                  display: grid;
                  place-items: center;
                `}
              >
                <FormattedMessage defaultMessage={'No Results Found'} description={'Notification that a search did not find any results'} />
              </div>
            )}
          </ListContainer>
          {/*<ListContainer>{props.loading ? <LoadingSpinner /> : selectedGroup.records.map((r) => props.render(r))}</ListContainer>*/}
        </Scrollbar>
      </div>
    </StyledDashboardList>
  );
}

export default DashboardList;
