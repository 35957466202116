/** @format */

export enum TableKey {
  ACCOUNT = 'accountTableSettings',
  DOCUMENT = 'documentTableSettings',
  ATTACHMENTS = 'attachments',
  ADMIN_ACCOUNT = 'adminAccount',
  ADMIN_DELETE_ACCOUNT = 'adminDeleteAccount',
  ADMIN_BRANCH = 'adminBranch',
  ADMIN_DELETE_BRANCH = 'adminDeleteBranch',
  ADMIN_COMPANY = 'adminCompany',
  ADMIN_DELETE_COMPANY = 'adminDeleteCompany',
  ADMIN_COMPANY_BRAND = 'adminBrand',
  ADMIN_DELETE_COMPANY_BRAND = 'adminDeleteBrand',
  ADMIN_CONTACT = 'adminContact',
  ADMIN_APPROVE_CONTACT = 'adminApproveContact',
  ADMIN_DELETE_CONTACT = 'adminDeleteContact',
  ADMIN_HELP = 'adminHelp',
  ADMIN_DELETE_HELP = 'adminDeleteHelp',
  ADMIN_SITE = 'adminSite',
  ADMIN_DELETE_SITE = 'adminDeleteSite',
  // ADMIN_USER = 'adminUser',
  ADMIN_INDUSTRY = 'adminIndustry',
  ADMIN_DELETE_INDUSTRY = 'adminDeleteIndustry',
  ADMIN_ERROR_LOG = 'adminErrorLog',
  ADMIN_DELETED_DOCUMENT = 'adminDeletedDocument',
}
