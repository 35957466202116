/** @format */

import { useIsAdmin } from '@common/application/hooks/useIsAdmin';
import { CompanyClient } from '@common/model/apiClient';
import { css } from '@emotion/react';
import { DashboardLink } from '@organisms/Dashboard/Dashboard';
import chroma from 'chroma-js';
import Image from 'next/image';
import React, { SetStateAction, useState } from 'react';
import styled from '@emotion/styled';
import { cssVars } from '@atoms/GlobalStyles';
import { motion } from 'framer-motion';
import { useQuery } from 'react-query';

interface SidebarProps {
  selectedLink: DashboardLink;
  setSelectedLink: React.Dispatch<SetStateAction<DashboardLink>>;
}

// const SIDEBAR_COLOR = cssVars.lightBlue;
const SIDEBAR_COLOR = cssVars.offWhite;
// const SIDEBAR_COLOR = '#f2f2f2';

const StyledSidebar = styled.div`
  user-select: none;
  height: 100%;
  background: ${SIDEBAR_COLOR};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 15px;
  @media (max-width: 768px) {
    //padding: 16px;
    //gap: 16px;
    width: 100%;
    height: fit-content;
    flex-direction: row;
    font-size: 0.7rem;
  }
`;

const TabHighlight = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5%;
  margin: auto 0;
  width: 97%;
  height: 50px;
  background: #e8e8e8;
  //background-color: #f1f1f1;
  border: 1px solid ${cssVars.charcoal};
  border-right: none;
  border-radius: 10px 0 0 10px;

  @media (max-width: 768px) {
    display: none;
  }
  :before {
    content: '';
    position: absolute;
    background: ${SIDEBAR_COLOR};
    z-index: 9;
    height: 100vh;
    width: 15px;
    bottom: 100%;
    //left: 97%;
    left: calc(100% - 8px);

    border-radius: 0 0 10px 0;
    cursor: default;
    border-right: 1px solid ${cssVars.charcoal};
    border-bottom: 1px solid ${cssVars.charcoal};
    //box-shadow: 1px -4px 8px 4px #55555565;
  }
  :after {
    border-right: 1px solid ${cssVars.charcoal};
    border-top: 1px solid ${cssVars.charcoal};
    cursor: default;
    content: '';
    position: absolute;
    background: ${SIDEBAR_COLOR};
    height: 100vh;
    width: 15px;
    top: 100%;
    left: calc(100% - 8px);
    //left: 97%;
    border-radius: 0 10px 0 0;
    //box-shadow: 1px 4px 8px 4px #55555565;
  }
`;

const SidebarTab = styled.div<{ selected: boolean }>`
  position: relative;
  height: 50px;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
  color: ${(props) => (props.selected ? cssVars.blue : cssVars.black)};
  display: grid;
  place-items: center;
  font-size: 1.3em;
  font-weight: bold;
  transition: color ${(props) => (props.selected ? 0.4 : 0.1)}s ease-in;
  padding: 8px;
  @media (max-width: 768px) {
    display: flex;
  }

  :hover {
    color: ${cssVars.blue};
    //color: ${(props) => (props.selected ? cssVars.black : cssVars.blue)};
  }
`;

const Logo = styled.div`
  height: 100px;
  width: 200px;
  position: relative;
`;

const LogoInnerWrapper = styled.div`
  //background: ${cssVars.lightGrey}88;
  //background: #f1f1f166;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 3px;
`;
const LogoWrapper = styled.div`
  width: calc(100% + 12px);
  margin-block: 20px;
  z-index: 3;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    //text-decoration: underline;
    //align-items: center;
  }
`;

function Sidebar(props: SidebarProps) {
  const [logo, setLogo] = useState(null);
  const { isAdmin } = useIsAdmin();

  const links = [
    { id: DashboardLink.USER, label: 'Your Dashboard' },
    { id: DashboardLink.COMPANY, label: 'Company Dashboard', adminOnly: true },
    { id: DashboardLink.SEARCH, label: 'Search Accounts' },
  ];

  const { isFetching: isCompanyFetching } = useQuery(['get_company'], () => CompanyClient.get(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    onSuccess: (d) => {
      setLogo(d.payload.logo.url as string);
    },
  });

  return (
    <StyledSidebar>
      <LogoWrapper>
        <LogoInnerWrapper>
          <Logo>{logo && <Image src={logo} layout={'fill'} objectFit={'contain'} alt={'Company Logo'} />}</Logo>
        </LogoInnerWrapper>
      </LogoWrapper>

      <SidebarNav>
        {links
          .filter((link) => !link.adminOnly || (link.adminOnly && isAdmin))
          .map((link) => (
            <SidebarTab
              key={'tab' + link.id}
              onClick={() => {
                props.setSelectedLink(link.id);
              }}
              selected={props.selectedLink === link.id}
            >
              <div
                css={css`
                  position: relative;
                  z-index: 2;
                  text-align: center;
                  margin-inline: 6px;
                `}
              >
                {link.label}
              </div>
              {props.selectedLink === link.id && <TabHighlight layoutId={'tabHighlight'} />}
            </SidebarTab>
          ))}
      </SidebarNav>
    </StyledSidebar>
  );
}

export default Sidebar;
