/** @format */

import EntityTag from '@atoms/EntityTag';
import { cssVars } from '@atoms/GlobalStyles';
import Scrollbar from '@atoms/Scrollbar';
import useSecureQuery from '@common/application/auth/useSecureQuery';
import { RecordStatus } from '@common/application/enums';
import useInfiniteScrollSearch from '@common/application/hooks/useTagSearch/useInfiniteScrollSearch';
import { Account } from '@common/model/apiClient/Account';
import { Tag } from '@common/model/apiClient/Tag';
import { User } from '@common/model/apiClient/User';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import CollapsibleContainer from '@molecules/CollapsibleContainer';
import TagCategoryMenu from '@molecules/TagCategoryMenu';
import DashboardAccountsListItem from '@organisms/Dashboard/DashboardAccountsListItem';
import DashboardDocListItem from '@organisms/Dashboard/DashboardDocListItem';
import DashboardList, { IDashboardListCategory } from '@organisms/Dashboard/DashboardList';
import React, { SetStateAction, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { AccountClient, DocumentClient } from '@common/model/apiClient';
import { css } from '@emotion/react';
import { useQuery } from 'react-query';
import FlexBox from '@atoms/FlexBox';
import { motion } from 'framer-motion';
import TagMenu, { TagWithCategory } from '@molecules/TagMenu';

interface IQuickNavCategory<T> {
  icon: IconName;
  label: EmotionJSX.Element;
  records: Array<T>;
  render: (record: T) => EmotionJSX.Element;
  loading: boolean;
  id: string;
}

export interface ITagFilterConfig {
  id: string;
  tagSearch: string;
  setTagSearch: React.Dispatch<SetStateAction<string>>;
  fetchNextTags(): void;
  allTagsFetched: boolean;
  tagOptions: Array<TagWithCategory>;
  selectedTags: Array<TagWithCategory>;
  setSelectedTags: React.Dispatch<SetStateAction<Array<TagWithCategory>>>;
  label: string | EmotionJSX.Element;
}
interface QuickNavProps<T> {
  categories: Array<IQuickNavCategory<T>>;
  tagCategories: Array<ITagFilterConfig>;
  // Unique identifier required for correct functioning of infinite scroll
}

const StyledQuickNav = styled(motion.div)`
  //margin-inline: 4px;
  //max-width: 100%;
  //flex-basis: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  box-sizing: border-box;
  position: relative;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  //gap: 8px;
`;

const TagPanel = styled(motion.div)`
  width: 49%;
  margin-left: calc(1% + 2px);
  overflow: hidden;
  background: linear-gradient(to bottom, ${cssVars.charcoal}, ${cssVars.charcoal}dd);
  position: absolute;
  top: 0;
  bottom: 0;
  //box-shadow: -6px -3px 5px -4px rgba(0, 0, 0, 0.4);
  border-right: 1px solid ${cssVars.white};
  padding: 8px;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 3;
  user-select: none;
`;

function QuickNav<T>(props: QuickNavProps<T>) {
  const [showTagMenu, setShowTagMenu] = useState(false);

  return (
    <FlexBox
      css={css`
        position: relative;
        max-width: 100%;
        width: 100%;
        height: 100%;
      `}
    >
      <StyledQuickNav
        initial={showTagMenu ? 'open' : 'closed'}
        animate={showTagMenu ? 'open' : 'closed'}
        variants={{
          open: { width: '50%', justifyContent: 'flex-end' },
          closed: { width: '100%', justifyContent: 'flex-start' },
        }}
        transition={{ duration: 0.5 }}
      >
        <DashboardList
          categories={props.categories}
          setShowTags={(bool) => {
            setShowTagMenu(bool);
          }}
        />
      </StyledQuickNav>
      <TagPanel
        initial={{ x: '0%', opacity: 0 }}
        animate={{ x: showTagMenu ? '100%' : '0%', opacity: showTagMenu ? 1 : 0 }}
        transition={{ x: { duration: 0.5 }, opacity: showTagMenu ? { duration: 0.2 } : { duration: 0.8 } }}
      >
        <Scrollbar disableXScroll={true}>
          <FlexBox
            flexDirection={'column'}
            gap={'8px'}
            alignItems={'center'}
            width={'100%'}
            css={css`
              h3 {
                color: ${cssVars.white};
                font-size: 0.9rem;
                font-weight: normal;
                text-align: left;
                margin: 0;
              }
            `}
          >
            <span
              css={css`
                color: ${cssVars.white};
                font-size: 1.2rem;
              `}
            >
              <FormattedMessage defaultMessage={'Select Filters'} />
            </span>

            {props.tagCategories.map((category, index) => (
              <CollapsibleContainer header={category.label} key={'tagMenu' + category.id} defaultOpen={index === 0}>
                <TagMenu
                  // tags={[]}
                  tags={category.tagOptions.filter((t) => !category.selectedTags.map((filter) => filter.id).includes(t.id))}
                  onSelect={(tag: TagWithCategory) => {
                    category.setSelectedTags((filters) => [...filters, tag]);
                  }}
                  icon={'plus'}
                  search={category.tagSearch}
                  setSearch={category.setTagSearch}
                  fetchNext={category.fetchNextTags}
                  hasMore={!category.allTagsFetched}
                  id={category.id}
                />
                <FlexBox
                  css={css`
                    width: min(250px, 100%);
                    flex-wrap: wrap;
                    align-content: flex-start;
                    gap: 6px;
                    padding: 4px;
                    //background: ${cssVars.lightGrey};
                    border-radius: 3px;
                  `}
                >
                  {category.selectedTags.map((tag) => (
                    <div
                      css={css`
                        border: 1px solid ${cssVars.white};
                        border-radius: 5px;
                      `}
                      key={`tag${tag.id}`}
                    >
                      <EntityTag
                        label={tag.label}
                        style={tag.category.style}
                        category={tag.category.name}
                        button={'multiply'}
                        onClick={() => {
                          category.setSelectedTags((filters) => filters.filter((selected) => selected.id !== tag.id));
                        }}
                      />
                    </div>
                  ))}
                </FlexBox>
              </CollapsibleContainer>
            ))}
          </FlexBox>
        </Scrollbar>
        {/* Content of your second component */}
      </TagPanel>
    </FlexBox>
  );
}

export default QuickNav;
