/** @format */
import { faCompassDrafting, faPalette, faScroll, faSortDown, faSortUp, faCircleStar, faBanBug } from '@fortawesome/pro-duotone-svg-icons';
// import { faStar as faSharpStar } from '@fortawesome/pro-sharp-svg-icons';
import { faFile, faFilePlus as faFilePlusL, faFileSignature, faPaperPlane, faQuestionCircle, faFileSlash } from '@fortawesome/pro-light-svg-icons';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboard,
  faDownload,
  faFileExcel,
  faLink,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faSort,
  faTrashUndo,
  faSearch,
  faTrashXmark as faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAngleLeft,
  faAngleRight,
  faArrowRightArrowLeft,
  faArrowsRotate,
  faArrowRight,
  faAsterisk,
  faBank,
  faBars,
  faBell,
  faBinoculars,
  faBlockQuote,
  faBold,
  faBug,
  faBuilding,
  faCamera,
  faCaretDown,
  faFile as fasFile,
  faBuildingMemo,
  faCaretUp,
  faCheck,
  faChevronDown as faChevronDownS,
  faChevronDown as fasChevronDown,
  faChevronLeft as faChevronLeftS,
  faChevronRight as faChevronRightS,
  faChevronUp as faChevronUpS,
  faCircleExclamation,
  faCircleNotch,
  faCircleQuestion,
  faClockRotateLeft,
  faCloudArrowDown,
  faCompassDrafting as faCompass,
  faCompress,
  faCopyright,
  faDollarSign,
  faDown,
  faDownload as faDLSolid,
  faEnvelope,
  faEuroSign,
  faExchange,
  faExpand,
  faFileCirclePlus,
  faFilePlus,
  faFloppyDisk,
  faFloppyDiskPen,
  faFolder,
  faFont,
  faGear,
  faH1,
  faH2,
  faH3,
  faHeader,
  faImage,
  faImageSlash,
  faIndustry,
  faItalic,
  faLanguage,
  faLink as faLinkS,
  faListOl,
  faListUl,
  faMagnifyingGlass,
  faMagnifyingGlassMinus as faMagnifyingGlassMinusS,
  faMagnifyingGlassPlus as faMagnifyingGlassPlusS,
  faMapLocationDot,
  faMaximize,
  faMemo,
  faMessageExclamation,
  faMinimize,
  faMinus,
  faMonitorWaveform,
  faMultiply,
  faPaintRoller,
  faPalette as fasPalette,
  faPaperclip,
  faPencil,
  faPercent,
  faPieChart,
  faPlus,
  faPrint,
  faRankingStar,
  faRectangleHistoryCirclePlus,
  faRectangleList,
  faScroll as fasScroll,
  faShop,
  faSigma,
  faSpinner,
  faStar,
  faStarSharp,
  faSterlingSign,
  faStrikethrough,
  faSymbols,
  faTag,
  faTextSlash,
  faTimesCircle,
  faTriangleExclamation,
  // faBuildingMemo,
  faUnderline,
  faUp,
  faUpload,
  faUsers,
  faWrench,
  faTrashUndo as fasTrashUndo,
  faChartLine,
  faCircleCheck,
  faEye,
} from '@fortawesome/pro-solid-svg-icons';

const faIcons = [
  faSortUp,
  faSort,
  faSortDown,
  faAlignCenter,
  faAlignJustify,
  faAngleLeft,
  faAngleRight,
  faArrowsRotate,
  faArrowRight,
  faArrowRightArrowLeft,
  faAsterisk,
  faBanBug,
  faBank,
  faBell,
  faBinoculars,
  faBlockQuote,
  faBold,
  faBug,
  faBuilding,
  faBuildingMemo,
  faCamera,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronDownS,
  faChevronLeft,
  faChevronLeftS,
  faChevronRight,
  faChevronRightS,
  faChevronUp,
  faChevronUpS,
  faCircleExclamation,
  faCircleQuestion,
  faCircleStar,
  faClockRotateLeft,
  faMessageExclamation,
  faCloudArrowDown,
  faCompass,
  faCompassDrafting,
  faCircleNotch,
  faCircleCheck,
  faClipboard,
  faCompress,
  faCopyright,
  faDLSolid,
  faDollarSign,
  faDown,
  faDownload,
  faEnvelope,
  faEuroSign,
  faExchange,
  faExpand,
  faEye,
  faFile,
  fasFile,
  faFileCirclePlus,
  faFileExcel,
  faFilePlus,
  faFilePlusL,
  faFileSignature,
  faFileSlash,
  faFloppyDisk,
  faFloppyDiskPen,
  faFolder,
  faFont,
  faGear,
  faHeader,
  faH1,
  faH2,
  faH3,
  faImage,
  faImageSlash,
  faIndustry,
  faItalic,
  faLanguage,
  faLink,
  faLinkS,
  faMagnifyingGlass,
  faMagnifyingGlassMinus,
  faMagnifyingGlassMinusS,
  faMagnifyingGlassPlus,
  faMagnifyingGlassPlusS,
  faMapLocationDot,
  faMaximize,
  faMemo,
  faMinimize,
  faMinus,
  faMonitorWaveform,
  faMultiply,
  faPaintRoller,
  faPalette,
  fasPalette,
  faPaperclip,
  faPaperPlane,
  faPencil,
  faPercent,
  faPieChart,
  faPlus,
  faPrint,
  faQuestionCircle,
  faRankingStar,
  faRectangleList,
  faRectangleHistoryCirclePlus,
  faSearch,
  faScroll,
  fasScroll,
  faShop,
  faSigma,
  faSpinner,
  faStar,
  faStarSharp,
  faSterlingSign,
  faStrikethrough,
  faSymbols,
  faTag,
  faTextSlash,
  faTrash,
  faTriangleExclamation,
  faUnderline,
  faUp,
  faUpload,
  faUsers,
  faBars,
  fasChevronDown,
  faListUl,
  faListOl,
  faAlignLeft,
  faAlignRight,
  faTimesCircle,
  faTrashUndo,
  fasTrashUndo,
  faWrench,
];
export default faIcons;
